var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Ajax } from './ajax/Ajax';
import { DOMHelper } from './dom/DOMHelper';
export class bpFileUploadHandler {
    /**
     * Klasse som håndterer DnD av filer
     */
    constructor(domId, multiple, readOnly) {
        this.multiple = multiple;
        this.readOnly = readOnly;
        this.info_timer = 0;
        this._files = [];
        const node = document.getElementById(domId);
        if (!node) {
            throw new Error('Finner ikke dom-node ' + domId + ' og hange DnD på');
        }
        this.dom_node = node;
        this.initialize(this.dom_node);
    }
    addExistingFile(fildata, previewUrl, fileUid) {
        const entry = {
            file: fildata,
            uid: fileUid,
            state: 'serverside',
            onload: [],
            onerror: [],
        };
        this._files.push(entry);
        this.addToUIList(entry);
        const simple = this.getDataAttr('simple_oneliner');
        if (simple) {
            this.insertIconThumbnail(entry);
        }
        else if (previewUrl) {
            this.insertPreviewThumbnail(entry, previewUrl);
        }
    }
    addToUIList(fileEntry) {
        const fileData = fileEntry.file;
        const liElement = document.createElement('li');
        liElement.className = 'file_list_entry';
        fileEntry.dom = liElement;
        liElement.fil_peker = fileEntry;
        let html = "<div class='wrapper'>";
        const simple = this.getDataAttr('simple_oneliner');
        if (!simple) {
            html += "<div class='thumb'></div>";
        }
        const hideMetadata = this.getDataAttr('hide_metadata');
        /*                if (this.read_only && this.getDataAttr('simple_oneliner')) {
                            html += "<div>";

                            html += "</div>";
                        } else */
        const extraFields = this.getExtraFields();
        if (!hideMetadata || !this.readOnly) {
            html += '<div class="metadata">';
            html += " <a class='show' style='font-weight: bold;'>";
            if (simple) {
                html += "<div class='thumb'></div>";
            }
            if (fileData.name) {
                /** @TODO bear: 2015.11.20 Må escape den her */
                html += fileData.name;
            }
            else {
                html += 'vis';
            }
            html += '</a>';
            if (simple) {
                html += '&nbsp;';
            }
            else {
                html += '<br />';
            }
            html += '<span title="' + DOMHelper.htmlEntities(fileData.type || 'n/a') + '">';
            html += this.normalizeNumber(fileData.size, 'B');
            html += '</span>';
            if (!simple) {
                html += '<br />';
                html += '<span class="toolbox">';
                if (!this.readOnly) {
                    html += "<a class='delete'>";
                    const icon = this.getTrashIcon();
                    if (icon) {
                        html += "<img src='" + DOMHelper.htmlEntities(icon) + "' />";
                    }
                    html += ' fjern</a> |';
                }
                html += '</span>';
            }
            html += '</div>';
            if (extraFields && extraFields.length) {
                if (!fileEntry.extra_dom_id) {
                    fileEntry.extra_dom_id = this.getNewExtraId();
                }
                html += "<div class='extra' id='" + fileEntry.extra_dom_id + "'></div>";
            }
        }
        html += '</div>';
        liElement.innerHTML = html;
        //              var list = document.getElementById('listdokument');
        const list = DOMHelper.query('.filliste ul', this.dom_node).item(0);
        list.insertBefore(liElement, null);
        if (extraFields && extraFields.length) {
            this.handleExtraFieldsForLi(liElement, fileEntry);
        }
    }
    deleteFile(fileEntry, onlyFromList = false) {
        /**
         * @TODO bear: 2015.07.20 Rydde opp interne variabler
         */
        if (fileEntry.state === 'transfer') {
            // Under overføring
            //                    xhr.blablahfinnutfunksjon();
            if (fileEntry.xhr) {
                fileEntry.xhr.abort();
            }
        }
        else if (!onlyFromList) {
            /**
             * Hvis only_from_list, så skal
             * vi ikke si ifra til server, bare fjerne fra GUI-liste
             * Brukes på single-opplasting, da serveren allerede
             * vet at den forrige er borte
             */
            let deleteUrl = this.getServiceUrl();
            if (!deleteUrl) {
                throw new Error('Mangler service-url');
            }
            deleteUrl += '&mode=delete';
            if (!fileEntry.uid) {
                throw new Error('Kan ikke slette uten file-uid');
            }
            deleteUrl += '&file_uid=' + encodeURIComponent(fileEntry.uid);
            /*                            notify_url += "&file_index="+response_data.file_index;
                                        fil.file_index = response_data.file_index;
                                        fil.uid = response_data.uid;
                                        fil.state = "uploaded";
            //                            fil.uid = "uploaded:"+response_data.file_index;
            */
            this.getAjaxHandler().ajaxVoid(deleteUrl);
        }
        const domNode = fileEntry.dom;
        if (domNode && domNode.parentElement) {
            domNode.parentElement.removeChild(domNode);
        }
        const fileIndex = this._files.indexOf(fileEntry);
        if (fileIndex >= 0) {
            this._files.splice(fileIndex, 1);
        }
    }
    dropboxIsReady(container) {
        const self = this;
        const options = {
            multiselect: this.multiple,
            success(filer) {
                self.dropboxSelect(filer);
            },
            cancel() {
                // void
            },
            linkType: 'direct', // preview eller direct
            /*
            extensions: []
            */
        };
        const button = window.Dropbox.createChooseButton(options);
        container.appendChild(button);
    }
    dropboxSelect(filer) {
        for (const i in filer) {
            const remoteFile = filer[i];
            this.remoteFileUpload(remoteFile);
        }
    }
    getAjaxHandler() {
        return Ajax.stdInstance();
    }
    getDataAttr(key) {
        return this.dom_node.getAttribute('data-' + key);
    }
    getExtraFields() {
        let extraFields = this.getDataAttr('extra-fields');
        if (!extraFields) {
            extraFields = this.getDataAttr('extra_fields');
        }
        if (extraFields) {
            return extraFields.split(/,/);
        }
        return null;
    }
    getExtraUrl() {
        return this.getDataAttr('extra-url');
    }
    getIconUrl() {
        return this.getDataAttr('icon-url');
    }
    getNewExtraId() {
        return 'extra_fields_' + ++bpFileUploadHandler._extra_id;
    }
    getServiceUrl() {
        return this.getDataAttr('service-url');
    }
    getTrashIcon() {
        return '';
        //return cerum.getBaseUrl() + "/bilder/trash.gif";
    }
    handleDragOver(dragEvent) {
        dragEvent.stopPropagation();
        dragEvent.preventDefault();
        if (dragEvent.dataTransfer) {
            dragEvent.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
        }
    }
    handleFileSelect(dragEvent) {
        let valgteFiler = null;
        if (dragEvent && dragEvent.dataTransfer) {
            dragEvent.stopPropagation();
            dragEvent.preventDefault();
            valgteFiler = dragEvent.dataTransfer.files; // FileList object.
        }
        else {
            const fileNode = DOMHelper.query('input.file_input', this.dom_node).item(0);
            if (fileNode instanceof HTMLInputElement) {
                valgteFiler = fileNode.files;
            }
            //                    document.getElementById("opplastfiler").files; // FileList object.
        }
        if (!valgteFiler) {
            return;
        }
        if (valgteFiler.length > 1 && !this.multiple) {
            this.showInfo("<div style='color: red; margin: 0.3em;'>Kan bare ta imot en fil.</div>", 3000);
            return;
        }
        const forrige = this._files.length ? this._files[0] : null;
        // Vis dem i lista først
        const files = [];
        for (let i = 0, valgtFil; i < valgteFiler.length; i++) {
            valgtFil = valgteFiler.item(i);
            if (!valgtFil) {
                continue;
            }
            // Legg til i lista over filer
            const entry = {
                file: valgtFil,
                dom: null,
                progress_dom: null,
                state: null,
                onload: [],
                onerror: [],
            };
            if (!this.multiple && forrige) {
                const self = this;
                const localForrige = forrige;
                entry.onload.push(() => {
                    // true her er only_from_list, altså bare
                    // slett fil fra gui-liste, ikke gi beskjed til server
                    self.deleteFile(localForrige, true);
                });
            }
            this._files.push(entry);
            files.push(entry);
            this.addToUIList(entry);
        }
        // Last opp
        for (let i = 0, valgtFil; i < files.length; i++) {
            valgtFil = files[i];
            // Start opplasting
            this.uploadFile(valgtFil);
        }
        for (let i = 0, valgtFil; i < files.length; i++) {
            valgtFil = files[i];
            // Klargjør preview av thumbnails
            this.previewThumbnail(valgtFil);
        }
    }
    hoverout() {
        DOMHelper.query('.drop_zone', this.dom_node).removeClass('dragover');
    }
    hoverover() {
        DOMHelper.query('.drop_zone', this.dom_node).addClass('dragover');
    }
    initDropbox(domNode) {
        const self = this;
        /**
         * Prepare Dropbox
         */
        if (!('Dropbox' in window)) {
            const scriptElement = document.createElement('script');
            scriptElement.type = 'text/javascript';
            /** < sc ript type="text/javascript" src="https://www.dropbox.com/static/api/2/dropins.js" id="dropboxjs" data-app-key="8cf6gzsp71w170y" >< / sc ript> **/
            scriptElement.id = 'dropboxjs';
            scriptElement.setAttribute('data-app-key', '8cf6gzsp71w170y');
            scriptElement.src = 'https://www.dropbox.com/static/api/2/dropins.js';
            scriptElement.onload = () => {
                self.dropboxIsReady(domNode);
            };
            document.body.appendChild(scriptElement);
        }
        else {
            self.dropboxIsReady(domNode);
        }
    }
    /**
     *
     *
     * en fil er delvis representert med et objekt inni her, med
     * følgende attributer:
     * {
     *   file:          Peker til file (native-js) objektet knyttet til fila (hvis den stammer fra GUI)
     *   preview_url:   Url til preview-image
     *   onerror: []    Array med callbacks som blir kallt dersom opplastingen skjærer seg
     *   onload: []     Array med callbacks som blir kjørt når opplasting er ferdig
     *   xhr:           Peker til XMLHttpRequest-objektet dersom den er under opplasting (kan avbrytes)
     *   state:         String som sier hvilken tilstand filen har. Alternativer:
     *     "transfer"   Filen er under opplasting
     *     "uploading"  Filer er lastet opp, og det er sendt "bekreftelsesmelding" til server ... (ikke spørr)
     *     "uploaded"   Filen er ferdig lastet opp
     *     "serverside" Filens eksistens er basert på rykter fra server, vi har ingen file-peker
     *
     *
     *
     */
    initialize(domNode) {
        if (!domNode) {
            console.error('Fatal: har ikke dom_node');
            return;
        }
        if (domNode.bpDnD) {
            return;
        }
        domNode.bpDnD = this;
        /**
         * Klikk i selve fil-lista
         */
        const liste = DOMHelper.query('output.filliste', domNode).item(0);
        liste.addEventListener('click', this.listeclick.bind(this), 
        /*      function() {
          self.listeclick.apply(self, arguments);
        },*/
        false);
        if (this.readOnly) {
            return;
        }
        // Setup klikk-listener i "velg-fil"-boksen
        const fileInput = DOMHelper.query('input.file_input', domNode).item(0);
        fileInput.addEventListener('change', () => {
            this.handleFileSelect(null);
        });
        // Setup the dnd listeners.
        //                var dropZone = document.getElementById('drop_zone');
        const dropZone = DOMHelper.query('.drop_zone', domNode).item(0);
        /**
         * Vi bruker anonyme funksjoner der vi gjør et
         * funksjonkall tilbake til våre interne funksjoner,
         * der vi får satt rett this-peker. Dette vil ikke
         * fungere dersom vi sender over våre funksjonspekere direkte.
         */
        dropZone.addEventListener('dragover', this.handleDragOver.bind(this), false);
        dropZone.addEventListener('drop', this.handleFileSelect.bind(this), false);
        dropZone.addEventListener('click', this.lastoppfiler.bind(this), false);
        dropZone.addEventListener('mouseover', this.hoverover.bind(this), false);
        dropZone.addEventListener('mouseout', this.hoverout.bind(this), false);
        dropZone.addEventListener('dragenter', this.hoverover.bind(this), false);
        dropZone.addEventListener('dragleave', this.hoverout.bind(this), false);
        this.initPlugins(domNode);
    }
    initPlugins(_domNode) {
        //                this.initDropbox(dom_node);
    }
    insertIconThumbnail(fileEntry) {
        let url = this.getIconUrl();
        if (!url) {
            throw new Error('Mangler Icon URL');
        }
        url += '&mimetype=' + fileEntry.file.type;
        this.insertPreviewThumbnail(fileEntry, url);
    }
    insertPreviewThumbnail(fileEntry, url, onload, noThrothling = false) {
        if (!fileEntry.dom) {
            // Ingen plass å putte den
            return;
        }
        const cont = DOMHelper.query('div.thumb', fileEntry.dom).item(0);
        if (!cont) {
            // Ingen plass å putte den
            return;
        }
        const imgNode = document.createElement('img');
        let cname;
        if (this.readOnly) {
            cname = 'preview';
            const previewWidth = this.getDataAttr('preview_width');
            const previewHeight = this.getDataAttr('preview_height');
            if (previewWidth) {
                imgNode.style.maxWidth = previewWidth + 'px';
            }
            if (previewHeight) {
                imgNode.style.maxHeight = previewHeight + 'px';
            }
        }
        else {
            cname = 'thumb';
            const thumbWidth = this.getDataAttr('thumb_width');
            const thumbHeight = this.getDataAttr('thumb_height');
            if (thumbWidth) {
                imgNode.style.maxWidth = thumbWidth + 'px';
            }
            if (thumbHeight) {
                imgNode.style.maxHeight = thumbHeight + 'px';
            }
        }
        imgNode.className = cname;
        imgNode.title = fileEntry.file.name;
        if (noThrothling) {
            if (onload) {
                imgNode.onload = onload;
            }
            this._installThumbnail(cont, imgNode, url);
            return;
        }
        const self = this;
        if (onload) {
            const origOnload = onload;
            onload = function (...argv) {
                origOnload.apply(imgNode, argv);
                self._loadSuccess(imgNode);
            };
        }
        else {
            onload = function () {
                self._loadSuccess(imgNode);
            };
        }
        imgNode.onload = onload;
        imgNode.onerror = function () {
            self._loadError(imgNode);
        };
        if (bpFileUploadHandler.thumbnailPending.length < bpFileUploadHandler.MAX_PENDING_REQUESTS) {
            bpFileUploadHandler.thumbnailPending.push(imgNode);
            this._installThumbnail(cont, imgNode, url);
        }
        else {
            bpFileUploadHandler.thumbnailQueue.push([
                imgNode,
                () => {
                    return this._installThumbnail(cont, imgNode, url);
                },
            ]);
        }
    }
    _installThumbnail(cont, imgNode, url) {
        if (!cont.ownerDocument.contains(cont)) {
            return false;
        }
        imgNode.src = url;
        cont.innerHTML = '';
        cont.appendChild(imgNode);
        return true;
    }
    _loadError(imgNode) {
        this._removeFromPendingAndLoadFromQueue(imgNode);
    }
    _loadSuccess(imgNode) {
        this._removeFromPendingAndLoadFromQueue(imgNode);
    }
    _removeFromPendingAndLoadFromQueue(imgNode) {
        const pendingListPosition = bpFileUploadHandler.thumbnailPending.indexOf(imgNode);
        if (pendingListPosition >= 0) {
            bpFileUploadHandler.thumbnailPending.splice(pendingListPosition, 1);
        }
        do {
            const next = bpFileUploadHandler.thumbnailQueue.shift();
            if (!next) {
                // Nobody in queue
                return;
            }
            const [nextImg, callback] = next;
            if (callback()) {
                // new request pending
                bpFileUploadHandler.thumbnailPending.push(nextImg);
                break;
            }
            // eslint-disable-next-line no-constant-condition
        } while (true);
    }
    /**
     * Trigger når bruker klikker i dropzone
     */
    lastoppfiler(_evnt) {
        const domNode = this.dom_node;
        const elem = DOMHelper.query('input.file_input', domNode).item(0);
        //                var elem = document.getElementById('opplastfiler');
        if (elem instanceof HTMLInputElement) {
            const mouseEvent = new MouseEvent('click', {
                bubbles: true,
                cancelable: false,
            });
            elem.dispatchEvent(mouseEvent);
        }
    }
    listeclick(event) {
        if (!(event.target instanceof HTMLElement)) {
            return;
        }
        let target = event.target;
        let onThumbnail = false;
        const debugOutput = false;
        if (target.tagName === 'IMG') {
            if (target.className.match(/preview/)) {
                onThumbnail = true;
            }
            else if (target.parentElement) {
                // Fang opp også klikk på ikon
                target = target.parentElement;
            }
        }
        if (target.tagName !== 'A' && !onThumbnail) {
            // Var ikke klikk på a-tag
            if (debugOutput) {
                console.error('Klikk var ikke på a-tag eller img-thumb');
            }
            return;
        }
        let liElement = target.parentElement;
        while (liElement && liElement.tagName !== 'LI') {
            liElement = liElement.parentElement;
            // Var ikke direkte under en li-element,
            // Ikke korrekt a-tag
        }
        if (!liElement || liElement.className !== 'file_list_entry') {
            // Ikke rett LI-tag
            if (debugOutput) {
                console.error('Klikk-event fant li-element uten class=file_list_entry');
            }
            return;
        }
        if (!liElement.fil_peker) {
            // Har ikke fil
            if (debugOutput) {
                console.error('Klikk-event fant li-element uten fil_peker');
            }
            return;
        }
        const fileEntry = liElement.fil_peker;
        if (target.className.match(/delete/)) {
            this.deleteFile(fileEntry);
        }
        else if (target.className.match(/show/) || target.className.match(/preview/)) {
            this.showFile(fileEntry);
        }
    }
    normalizeNumber(numValue, suffix) {
        const log2 = Math.floor(Math.log(numValue) / Math.LN2 / 10);
        const prefix = ' kMGTP'.substr(log2, 1);
        numValue = Math.round((100 * numValue) / Math.pow(1024, log2)) / 100;
        return numValue + ' ' + prefix + suffix;
    }
    previewThumbnail(fileEntry, delayed = false) {
        //                var simple = this.getDataAttr('simple_oneliner');
        const self = this;
        // Bare tillate preview av de mest kjente bildetypene
        const type = fileEntry.file.type;
        if (!(type && type.match(/^image\/(jpe?g|png|gif|bmp|ico)/))) {
            return this.insertIconThumbnail(fileEntry);
        }
        if (fileEntry.file.size > 5 * 1024 * 1024 && !delayed) {
            // Hvis bildet er over 5meg, så venter vi til
            // etterpå for å vise frem thumbnail, så ser
            // progressbar ut OK.
            fileEntry.onload.push(() => {
                this.previewThumbnail(fileEntry, true);
            });
            return;
        }
        if (fileEntry.file instanceof File) {
            const file = fileEntry.file;
            // Hvis vi ikke har et orntlig fil-objekt, så får vi ikke gjort noe her
            /**
             * Lag thumbnail av bilde
             */
            const useNewPreviewMethod = true;
            if (useNewPreviewMethod) {
                // Alternative måte å gjøre det på.
                const objectUrl = window.URL.createObjectURL(file);
                self.insertPreviewThumbnail(fileEntry, objectUrl, () => {
                    window.URL.revokeObjectURL(objectUrl);
                });
                return;
            }
            else {
                /**
                 * Vi lar denne ligge her enn så lenge, kanskje vi må gå tilbake til denne
                 */
                const reader = new FileReader();
                // Closure to capture the file information.
                reader.onload = (onloadEvent) => {
                    // Render thumbnail.
                    const target = onloadEvent.target;
                    if (target instanceof FileReader) {
                        self.insertPreviewThumbnail(fileEntry, String(target.result));
                    }
                };
                // Read in the image file as a data URL.
                reader.readAsDataURL(file);
                self.insertPreviewThumbnail(fileEntry, window.URL.createObjectURL(fileEntry.file));
            }
        }
        else {
            return;
        }
    }
    // Oppdater progressbar for fil
    progress(fileEntry, progressEvent) {
        if (!fileEntry.dom) {
            // Fil har ikke dom-peker enda? Bilde-preview som ikke er ferdig?
            return;
        }
        if (!fileEntry.progress_dom) {
            // Fil har ikke progress-dom-element laget, da må vi ordne det
            const prog = document.createElement('progress');
            prog.setAttribute('max', '100');
            fileEntry.dom.appendChild(prog);
            fileEntry.progress_dom = prog;
        }
        if (typeof progressEvent === 'object') {
            const progressPercent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            fileEntry.progress_dom.setAttribute('value', String(progressPercent));
        }
        else if (progressEvent === -1) {
            fileEntry.progress_dom.style.display = 'none';
        }
    }
    remoteFileUpload(remoteFile) {
        const self = this;
        /**
        file = {

            // Name of the file.
            name: "filename.txt",

            // URL to access the file, which varies depending on the linkType specified when the
            // Chooser was triggered.
            link: "https://...",

            // Size of the file in bytes.
            bytes: 464,

            // URL to a 64x64px icon for the file based on the file's extension.
            icon: "https://...",

            // A thumbnail URL generated when the user selects images and videos.
            // If the user didn't select an image or video, no thumbnail will be included.
            thumbnailLink: "https://...?bounding_box=75&mode=fit",
        };
        */
        let uploadUrl = this.getServiceUrl();
        if (!uploadUrl) {
            throw new Error('Mangler service-url');
        }
        uploadUrl += '&mode=remote';
        uploadUrl += '&remote_url=' + escape(remoteFile.link);
        uploadUrl += '&remote_size=' + String(Number(remoteFile.bytes));
        uploadUrl += '&remote_name=' + escape(remoteFile.name);
        const fileData = {
            size: remoteFile.bytes,
            name: remoteFile.name,
        };
        const fileEntry = {
            file: fileData,
            dom: null,
            progress_dom: null,
            state: null,
            onload: [],
            onerror: [],
        };
        self._files.push(fileEntry);
        self.addToUIList(fileEntry); // , data.file_uid);
        if (remoteFile.thumbnailLink) {
            this.insertPreviewThumbnail(fileEntry, remoteFile.thumbnailLink);
        }
        /*{
          "size": 2798703,
            "name": "2015-10-15 06.52.36.jpg",
              "type": "image/jpeg"
              }*/
        self.progress(fileEntry);
        this.getAjaxHandler().ajaxVoid(uploadUrl, {
            load(responsetxt) {
                //           try {
                const data = JSON.parse(responsetxt);
                fileEntry.state = 'uploaded';
                fileEntry.uid = data.file_uid;
                self.progress(fileEntry, -1);
                //             } catch(e) {}
            },
            error() {
                // void
            },
        });
    } // end function dropboxSelect
    showFile(fileEntry) {
        let url = this.getServiceUrl();
        if (!url) {
            throw new Error('Mangler service-url');
        }
        url += '&mode=download';
        if (fileEntry.uid) {
            url += '&file_uid=' + escape(fileEntry.uid);
        }
        window.open(url, '_new');
        //                dojo.addClass(fil.dom, 'fadout');
    }
    showInfo(infoHtml, duration) {
        const node = DOMHelper.query('.dnd_info', this.dom_node).item(0);
        if (!node) {
            return;
        }
        node.innerHTML = infoHtml;
        if (this.info_timer) {
            window.clearTimeout(this.info_timer);
        }
        if (duration) {
            this.info_timer = window.setTimeout(() => {
                node.innerHTML = '';
            }, duration);
        }
    }
    uploadCompleted(fileEntry) {
        // Kjører eventuelle laste-callbacks på fila
        for (let i = 0; i < fileEntry.onload.length; i++) {
            fileEntry.onload[i]();
        }
    }
    uploadFailed(fileEntry) {
        // Kjører eventuelle feil-callbacks på fila
        for (let i = 0; i < fileEntry.onerror.length; i++) {
            fileEntry.onerror[i]();
        }
        if (fileEntry.dom) {
            const domNode = fileEntry.dom;
            DOMHelper.addClass(domNode, 'failed');
            const self = this;
            window.setTimeout(() => {
                DOMHelper.addClass(domNode, 'fadeout');
                self.deleteFile(fileEntry);
            }, 2);
        }
    }
    /**
     * Last opp til server
     */
    uploadFile(fileEntry) {
        const self = this;
        const xhrRequest = new XMLHttpRequest();
        // Initialiser progress-bar ?
        this.progress(fileEntry);
        xhrRequest.upload.addEventListener('progress', (progressEvent) => {
            self.progress(fileEntry, progressEvent);
        }, false);
        let uploadUrl = this.getServiceUrl();
        if (!uploadUrl) {
            throw new Error('Mangler service-url');
        }
        let notifyUrl = uploadUrl;
        uploadUrl += '&mode=upload';
        uploadUrl += '&name=' + escape(fileEntry.file.name);
        notifyUrl += '&mode=complete';
        xhrRequest.onreadystatechange = (_evnt) => {
            if (xhrRequest.readyState !== XMLHttpRequest.DONE) {
                return;
            }
            if (xhrRequest.status !== 200) {
                self.uploadFailed(fileEntry);
                return;
            }
            const responseData = JSON.parse(xhrRequest.responseText);
            notifyUrl += '&file_index=' + responseData.file_index;
            //                            fil.file_index = response_data.file_index;
            //                            fil.uid = response_data.uid;
            fileEntry.state = 'uploading';
            //                            fil.uid = "uploaded:"+response_data.file_index;
            self.getAjaxHandler().ajaxVoid(notifyUrl, {
                load(responsetxt) {
                    try {
                        const data = JSON.parse(responsetxt);
                        fileEntry.state = 'uploaded';
                        fileEntry.uid = data.file_uid;
                        self.uploadCompleted(fileEntry);
                    }
                    catch (_error) {
                        // void
                    }
                },
            });
            // skjul progressbar
            self.progress(fileEntry, -1);
        };
        xhrRequest.open('POST', uploadUrl, true);
        // Denne er vel overflødig? Er vel med i posten?
        //                xhr.setRequestHeader('X-Filename', fil.file.name);
        fileEntry.xhr = xhrRequest;
        fileEntry.state = 'transfer';
        if (fileEntry.file instanceof File) {
            xhrRequest.send(fileEntry.file);
        }
    }
    handleExtraFieldsForLi(liElement, fileEntry) {
        if (fileEntry.uid) {
            this.innerHandleExtraFieldsForLi(liElement, fileEntry);
        }
        else {
            fileEntry.onload.push(() => {
                this.innerHandleExtraFieldsForLi(liElement, fileEntry);
            });
        }
    }
    innerHandleExtraFieldsForLi(_liElement, fileEntry) {
        return __awaiter(this, void 0, void 0, function* () {
            const extraUrl = this.getExtraUrl();
            if (!extraUrl) {
                return;
            }
            if (!fileEntry.extra_dom_id) {
                return;
            }
            const domNode = document.getElementById(fileEntry.extra_dom_id);
            if (!domNode) {
                return;
            }
            const extraFields = this.getExtraFields();
            if (!extraFields) {
                return;
            }
            for (const i in extraFields) {
                const field = extraFields[i];
                let url = extraUrl;
                url += '&felt=' + field;
                if (fileEntry.uid) {
                    url += '&uid=' + fileEntry.uid;
                }
                const span = document.createElement('span');
                domNode.appendChild(span);
                ((url, span) => __awaiter(this, void 0, void 0, function* () {
                    const response = yield Ajax.stdInstance().fetch(url);
                    span.innerHTML = yield response.text();
                }))(url, span);
            }
        });
    }
}
bpFileUploadHandler._extra_id = 0;
bpFileUploadHandler.thumbnailPending = [];
bpFileUploadHandler.thumbnailQueue = [];
bpFileUploadHandler.MAX_PENDING_REQUESTS = 3;
